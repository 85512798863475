var myExtObject = (function () {
  return {
    loadFlag: function (country) {
      $("#phone").intlTelInput({
        autoPlaceholder: "off",
        dropdownContainer: "body",
        initialCountry: "in",
        separateDialCode: true,
        utilsScript: "/assets/js/utils.js"
      });
    },

     initMap: function(latitude ,longitude) {
       var uluru = {lat: latitude, lng: longitude};
       var map = new google.maps.Map(
         document.getElementById('mapss'), {zoom: 15, center: uluru});
       var marker = new google.maps.Marker({position: uluru, map: map});
  },
    initMap1: function(latitude ,longitude) {
      var uluru = {lat: latitude, lng: longitude};
      var map = new google.maps.Map(
        document.getElementById('map1'), {zoom: 15, center: uluru});
      var marker = new google.maps.Marker({position: uluru, map: map});
    },
    initMap2: function(latitude ,longitude) {
      var uluru = {lat: latitude, lng: longitude};
      var map = new google.maps.Map(
        document.getElementById('map2'), {zoom: 15, center: uluru});
      var marker = new google.maps.Marker({position: uluru, map: map});
    },
    initMap3: function(latitude ,longitude) {
      var uluru = {lat: latitude, lng: longitude};
      var map = new google.maps.Map(
        document.getElementById('map3'), {zoom: 15, center: uluru});
      var marker = new google.maps.Marker({position: uluru, map: map});
    },

    loadFlagId: function (i) {
      $("#phone-" + i).intlTelInput({
        autoPlaceholder: "off",
        dropdownContainer: "body",
        initialCountry: "in",
        separateDialCode: true,
        utilsScript: "/assets/js/utils.js"
      });
    },

    getCountryCodeID: function (i) {
      var countryDetails = $('#selected-flag-' + i).attr("title");
      return countryDetails;

    },

    getCountryCode: function () {
      var countryDetails = $('#selected-flag').attr("title");
      return countryDetails;

    },

    loadFlagForInvite: function (country) {
      // alert(country);
      $("#invite-phone").intlTelInput({
        autoPlaceholder: "off",
        dropdownContainer: "body",
        initialCountry: "in",
        separateDialCode: true,
        utilsScript: "/assets/js/utils.js"
      });


    },

    loadFlagForEdit: function (country) {
      $("#mobile-edit").intlTelInput({
        autoPlaceholder: "off",
        dropdownContainer: "body",
        onlyCountries: [country],
        initialCountry: country,
        separateDialCode: true,
        utilsScript: "/assets/js/utils.js"
      });
    },

    DetectAndServe: function () {

      if (getMobileOperatingSystem() == "Android") {
        window.location.href = "market://details?id=com.odigolive";
      }
      if (getMobileOperatingSystem() == "iOS") {
        window.location.href = "#";
      }
      if (getMobileOperatingSystem() == "unknown") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.odigolive";
      }
    },
    setTabFocus: function () {
      if ($(window).width() <= 420) {

        $('.nav-tabs a[data-target="#mobile"]').tab('show');
      } else {

        $('.nav-tabs a[data-target="#web"]').tab('show');
      }
    },

    archiveGroup: function (divId) {
      $("#div-" + divId).hide();
    },

    toastMsg: function () {
      $('.toast-msg').fadeIn(300).delay(1000).fadeOut(300);
    },

    changefocus: function (id) {
      document.getElementById(id).focus();
    },

    allowBackpace: function (id , check) {
      var k = event.keyCode;
      if (check == true ) {
        if (k == 8) {

        }
        else {
          document.getElementById(id).focus();
          return;
        }
      }

    },



    getCountryName: function (country) {
      var subArray;
      var countryShortName = "";
      for (var i = 0; i < allCountries.length; i++) {
        subArray = allCountries[i];
        if (subArray.name.trim().toUpperCase() === country.trim().toUpperCase()) {
          countryShortName = subArray.iso2;
        }
      }
      this.loadFlagForEdit(countryShortName);
      countryShortName = "";

    },
    loadWebCam: function (type) {

      var player;
      if (type == "video") {
        player = videojs('attach-video', {
          controls: true,
          loop: false,
          width: 400,
          height: 250,

          plugins: {
            record: {
              image: false,
              audio: true,
              video: true,
              maxLength: 30,
              debug: true,
              audioEngine: 'recordrtc',
              videoMimeType: 'video/webm'
            }
          }
        });
      } else if (type == "audio") {
        player = videojs('attach-audio', {
          controls: true,
          width: 400,
          height: 70,
          plugins: {
            wavesurfer: {
              src: "live",
              waveColor: "#71D3D3",
              progressColor: "#2E732D",
              debug: true,
              cursorWidth: 1,
              msDisplayMax: 60,
              hideScrollbar: false
            },
            record: {
              audio: true,
              video: false,
              maxLength: 20,
              debug: true
            },
          }
        });
      } else {
        player = videojs("attach-image", {
          controls: true,
          width: 400,
          height: 250,
          controlBar: {
            volumeMenuButton: false,
            fullscreenToggle: false
          },
          plugins: {
            record: {
              image: true,
              debug: true
            }
          }
        });

      }
      return player;
    },
    downloadFile: function (fileUrl) {
      var res;
      // window.location = fileUrl;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", fileUrl);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status && xhr.status === 200) {
          savePdf(xhr.response, "dropboxFile");
        }
      }
      xhr.send();

      // return key;
    },
    getDropBoxFile: function () {
      var file = localStorage.getItem("dropboxFile");
      return file;

    },
    getDate: function () {
      var time = new Date();
      var day = time.getDate();
      var month = new Array();
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";
      var months = month[time.getMonth()];
      var year = time.getFullYear();
      return day + " " + months + " " + year
    },





    loadCalender: function (id) {
      $('#' + id).datepicker({
        autoclose: true,
        startDate: '-0m',
      });
    },

    loadClock: function (id) {
      $('#' + id).clockpicker({
        autoclose: true,
        placement: 'top',
        align: 'left',
        format:"HH:MM:SS",
        twelvehour: false,
      });
    },

    findBwoser: function () {
      var browser = "";
      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        browser = 'opera';
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        browser = 'chrome';
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        browser = 'safari';
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        browser = 'firefox';
      } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
      {
        browser = 'IE';
      } else {
        browser = "NIL";
      }

      return browser;

    },
    getFormatedDate: function (data) {
      var dataArr;
      var type;
      if (data.includes("/")) {
        dataArr = data.split("/");
        type = "slash";
      }

      if (data.includes("-")) {
        dataArr = data.split("-");
        type = "hiphan";

      }

      if (dataArr != "" && dataArr != null) {
        var index = Math.round(dataArr[1])
        var month = [];
        month[1] = "JAN";
        month[2] = "FEB";
        month[3] = "MAR";
        month[4] = "APR";
        month[5] = "MAY";
        month[6] = "JUN";
        month[7] = "JUL";
        month[8] = "AUG";
        month[9] = "SEP";
        month[10] = "OCT";
        month[11] = "NOV";
        month[12] = "DEC";
      }
      if (type == "slash") {
        return dataArr[0] + " " + month[index] + " " + dataArr[2]
      } else {
        return dataArr[2] + " " + month[index] + " " + dataArr[0]

      }

    },
    getWebinarDate: function (data) {
      var dataArr;
      var type;

      dataArr = data.split("-");


      if (dataArr != "" && dataArr != null) {
        var index = Math.round(dataArr[1])
        var month = [];
        month[1] = "JAN";
        month[2] = "FEB";
        month[3] = "MAR";
        month[4] = "APR";
        month[5] = "MAY";
        month[6] = "JUN";
        month[7] = "JUL";
        month[8] = "AUG";
        month[9] = "SEP";
        month[10] = "OCT";
        month[11] = "NOV";
        month[12] = "DEC";
      }

      return dataArr[2] + " " + month[index] + " " + dataArr[0]


    },

    loadVideoForRemarks: function () {
      var player;
      player = videojs('video-remark', {
        // video.js options
        controls: true,
        loop: false,
        width: 400,
        height: 250,

        plugins: {
          record: {
            image: false,
            audio: true,
            video: true,
            maxLength: 30,
            debug: true,
            audioEngine: 'recordrtc',
            videoMimeType: 'video/webm'
          }
        }
      });

      return player;
    },
    stopMedia: function () {
      $("video").each(function () {
        $(this).get(0).pause();
      });

      $("audio").each(function () {
        $(this).get(0).pause();
      });
    },
    indexedDbSupports: function () {
      window.indexedDB = window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB;
      if (window.indexedDB) {
        return true;
      } else {
        return false;
      }
    },
    objToArray: function (obj) {
      var arr = [];
      arr = obj.entries();
    },

    getOptionsValue: function (name) {
      var data = [];
      var checkboxes = document.getElementsByName(name);
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          data.push(checkboxes[i].value);
        }
      }

      return data;
    },
    timeConvert: function (time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    },

    step1: function () {
      function setClasses(index, steps) {
        if (index < 0 || index > steps) return;
        if (index == 0) {
          $("#prev").prop('disabled', true);
        } else {
          $("#prev").prop('disabled', false);
        }
        if (index == steps) {
          $("#next").text('done');
        } else {
          $("#next").text('Next');
        }
        $("ul li").each(function () {
          $(this).removeClass();
        });
        $("ul li:lt(" + index + ")").each(function () {
          $(this).addClass("done");
        });
        $("ul li:eq(" + index + ")").addClass("active");
        var p = index * (100 / steps);
        $("#prog").width(p + '%');
      }

      $(".step-wizard ul a").click(function () {
        var step = $(this).find("span.step")[0].innerText;
        var steps = $(".step-wizard ul li").length;
        setClasses(step - 1, steps - 1)
      });
      $("#prev").click(function () {
        var step = $(".step-wizard ul li.active span.step")[0].innerText;
        var steps = $(".step-wizard ul li").length;
        setClasses(step - 2, steps - 1);
      });
      $("#next").click(function () {
        if ($(this).text() == 'done') {
        }
        else {
          var step = $(".step-wizard ul li.active span.step")[0].innerText;
          var steps = $(".step-wizard ul li").length;
          setClasses(step, steps - 1);
        }
      });
      setClasses(0, $(".step-wizard ul li").length);
    },


    header1: function () {
      $(document).ready(function () {
        $('ul li a').click(function () {
          $('li a').removeClass("active");
          $(this).addClass("active");

        });
      });
      return this;
    },

    getMTFOptionText: function (optionObj, optionType) {
      if (optionType === 'LHSOption') {
        if (optionObj.hasOwnProperty('option1')) {
          return optionObj.option1;
        } else if (optionObj.hasOwnProperty('option2')) {
          return optionObj.option2;
        } else if (optionObj.hasOwnProperty('option3')) {
          return optionObj.option3;
        } else if (optionObj.hasOwnProperty('option4')) {
          return optionObj.option4;
        } else if (optionObj.hasOwnProperty('option5')) {
          return optionObj.option5;
        } else if (optionObj.hasOwnProperty('option6')) {
          return optionObj.option6;
        } else if (optionObj.hasOwnProperty('option7')) {
          return optionObj.option7;
        } else if (optionObj.hasOwnProperty('option8')) {
          return optionObj.option8;
        } else if (optionObj.hasOwnProperty('option9')) {
          return optionObj.option9;
        } else if (optionObj.hasOwnProperty('option10')) {
          return optionObj.option10;
        }
      } else if (optionType === 'RHSOption') {
        if (optionObj.hasOwnProperty('A')) {
          return optionObj.A;
        } else if (optionObj.hasOwnProperty('B')) {
          return optionObj.B;
        } else if (optionObj.hasOwnProperty('C')) {
          return optionObj.C;
        } else if (optionObj.hasOwnProperty('D')) {
          return optionObj.D;
        } else if (optionObj.hasOwnProperty('E')) {
          return optionObj.E;
        } else if (optionObj.hasOwnProperty('F')) {
          return optionObj.F;
        } else if (optionObj.hasOwnProperty('G')) {
          return optionObj.G;
        } else if (optionObj.hasOwnProperty('H')) {
          return optionObj.H;
        } else if (optionObj.hasOwnProperty('I')) {
          return optionObj.I;
        } else if (optionObj.hasOwnProperty('J')) {
          return optionObj.J;
        }
      } else {
        return '';
      }
    },

    getMTFOptionKey: function (optionObj, optionType) {
      if (optionType === 'RHSOption') {
        if (optionObj.hasOwnProperty('A')) {
          return 'A';
        } else if (optionObj.hasOwnProperty('B')) {
          return 'B';
        } else if (optionObj.hasOwnProperty('C')) {
          return 'C';
        } else if (optionObj.hasOwnProperty('D')) {
          return 'D';
        } else if (optionObj.hasOwnProperty('E')) {
          return 'E';
        } else if (optionObj.hasOwnProperty('F')) {
          return 'F';
        } else if (optionObj.hasOwnProperty('G')) {
          return 'G';
        } else if (optionObj.hasOwnProperty('H')) {
          return 'H';
        } else if (optionObj.hasOwnProperty('I')) {
          return 'I';
        } else if (optionObj.hasOwnProperty('J')) {
          return 'J';
        }
      } else {
        return '';
      }
    }

    //
  };

  $(document).ready(function () {
    $('.dropdown-content').on("click", function (e) {
      $(this).next('ul').toggle();
      e.stopPropagation();
      e.preventDefault();
    });
  });

  $("#myCarousel").carousel();
  // $(".nav .nav-link").on("click", function(){
  //   $(".nav").find(".active").removeClass("active");
  //   $(this).addClass("active");
  // });
  //
  // $('ul li a').click(function(){ $('li a').removeClass("active"); $(this).addClass("active"); });


  // $(function() {
  //   $('#nav li a').click(function() {
  //     $('#nav li').removeClass();
  //     $($(this).attr('routerLink')).addClass('active');
  //   });
  // });

  // $(document).ready(function () {
  //   $('.nav li a').click(function(e) {
  //
  //     $('.nav li.active').removeClass('active');
  //
  //     var $parent = $(this).parent();
  //     $parent.addClass('active');
  //     e.preventDefault();
  //   });
  // });


})(myExtObject || {})





